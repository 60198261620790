import React from "react";
import styled from "styled-components";
import loadable from '@loadable/component'
import { StaticImage } from "gatsby-plugin-image";

import media from "@styles/media";
import colors from "@styles/colors";
import text from "@styles/text";

import { ReactComponent as SustainabilitySVG } from "@svg/sustainability.svg";
import { ReactComponent as InnovationSVG } from "@svg/innovation.svg";
import { ReactComponent as InformationSVG } from "@svg/information.svg";
import { ReactComponent as PrecisionSVG } from "@svg/precision.svg";
import { ReactComponent as CollaborationSVG } from "@svg/collaboration.svg";
import { ReactComponent as IntegritySVG } from "@svg/integrity.svg";
import { ReactComponent as DiversitySVG } from "@svg/diversity.svg";

const Animation = loadable(() => import("@components/Animation"));

const Values: React.FC = () => {

  return (
    <Wrapper>
      <ValuesBG >
        <StaticImage
          src={'../../images/png/values.png'} 
          alt="Value"
          placeholder="blurred"
          style={{width: '100%', height: '100%'}}
        />
      </ValuesBG>
      <Title>Our core values</Title>
      <CardContainer>
        <Card>
          <FlexColumn>
            <Sustainability />
            <Text>01. Sustainability</Text>
          </FlexColumn>
          <P>
            Environmentalism is the driving force behind our mission. By
            minimizing our impact on the environment, we’re investing in a
            better future for mankind. We’re taking huge strides to be good
            stewards of this planet we call home.
          </P>
        </Card>
        <Card>
          <FlexColumn>
            <Innovation />
            <Text>02. Innovation</Text>
          </FlexColumn>
          <P>
            We never settle for “good enough”. Instead, we challenge the status
            quo by taking on every obstacle with persistence, creativity, and
            grit. We are deeply committed to continuous improvement and pushing
            the boundaries of what is possible.
          </P>
        </Card>
        <Card>
          <FlexColumn>
            <Information />
            <Text>03. Information</Text>
          </FlexColumn>
          <P>
            We keep the channels open and proactively relay information across
            teams for fluency of operations. We listen with intention. Above
            all, in every interaction, we speak with respect, kindness, and
            humility.
          </P>
        </Card>
        <Card>
          <FlexColumn>
            <Precision />
            <Text>04. Precision</Text>
          </FlexColumn>
          <P>
            Its our standard to do things right the first time. It’s our
            protocol to double-check our work. By wielding data and science as
            our go-to tools, we strive for the highest level of accuracy and
            efficiency. Quality is our signature in everything we do.
          </P>
        </Card>
        <Card>
          <FlexColumn>
            <Collaboration />
            <Text>05. Collaboration</Text>
          </FlexColumn>
          <P>
            We are a group of talented individuals who realize we can only
            accomplish our goals when we use those talents together. Since we
            are one team working toward a unified goal, we compromise for the
            sake of the greater mission.
          </P>
        </Card>
        <Card>
          <FlexColumn>
            <Integrity />
            <Text>06. Integrity</Text>
          </FlexColumn>
          <P>
            We follow through with our commitments, showing up with honesty,
            transparency, and responsibility in the process. We establish and
            maintain each other’s trust, supporting and challenging one another
            in our growth along the way.
          </P>
        </Card>
        <Card>
          <FlexColumn>
            <Diversity />
            <Text>07. Diversity & Inclusion</Text>
          </FlexColumn>
          <P>
            Our individual differences make up our team’s strengths. We accept
            persons of all nations, races/ethnicities, beliefs, genders, sexual
            identities, disabilities, and cultures. We have a zero tolerance
            policy for discrimination, harassment, and disrespect, because we
            value the safety and wellbeing of our team.
          </P>
        </Card>
      </CardContainer>
      <AnimationWrapper>
        <Gradient2 />
        <Animation />
      </AnimationWrapper>
    </Wrapper>
  );
};

export default Values;

const Wrapper = styled.section`
  background-color: ${colors.culturedWhite40};
  position: relative;

  ${media.fullWidth} {
    padding-top: 20.278vw;
    padding-left: 3.542vw;
    padding-right: 6.458vw;
    padding-bottom: 22vw;
  }

  ${media.desktop} {
    padding-top: 20.278vw;
    padding-left: 3.542vw;
    padding-right: 6.458vw;
    padding-bottom: 22vw;
  }

  ${media.tablet} {
    padding-top: 36.407vw;
    padding-left: 13.413vw;
    padding-right: 13.413vw;
    padding-bottom: 12.695vw;
  }

  ${media.mobile} {
    padding-top: 65.6vw;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-bottom: 64.267vw;
  }
`;

const ValuesBG = styled.div`
  position: absolute;

  ${media.fullWidth} {
    top: 5.528vw;
    left: -2vw;
    width: 67.361vw;
    height: 15.889vw;
  }

  ${media.desktop} {
    top: 5.528vw;
    left: -2vw;
    width: 67.361vw;
    height: 15.889vw;
  }

  ${media.tablet} {
    top: 8vw;
    left: 0vw;
    width: 94.168vw;
    height: 23.198vw;
  }

  ${media.mobile} {
    top: 8vw;
    left: 0vw;
    width: 94.168vw;
    height: 23.198vw;
  }
`;

const Title = styled.h2`
  position: absolute;
  z-index: 2;
  color: ${colors.black};

  ${media.fullWidth} {
    ${text.desktopXLHeading};
    top: 10.556vw;
    left: 11.389vw;
  }

  ${media.desktop} {
    ${text.desktopXLHeading};
    top: 10.556vw;
    left: 11.389vw;
  }

  ${media.tablet} {
    ${text.tabletXLHeading}
    top: 15.808vw;
    left: 13.054vw;
  }

  ${media.mobile} {
    ${text.mobileMHeading}
    top: 19.733vw;
    left: 6.667vw;
  }
`;

const CardContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;

  ${media.fullWidth} {
    flex-wrap: wrap;
  }

  ${media.desktop} {
    flex-wrap: wrap;
  }

  ${media.tablet} {
    flex-direction: column;
  }

  ${media.mobile} {
    flex-direction: column;
  }
`;

const Card = styled.div`
  background-color: ${colors.culturedWhite40};
  display: flex;
  border: 1px solid ${colors.black};
  box-sizing: border-box;

  ${media.fullWidth} {
    align-items: center;
    justify-content: space-between;
    margin-right: 3.958vw;
    margin-bottom: 2.778vw;
    width: 42vw;
    height: 15.347vw;
    padding-top: 2.431vw;
    padding-bottom: 2.431vw;
    padding-left: 3.403vw;
    padding-right: 3.403vw;

    :nth-of-type(even) {
      margin-right: 0px;
    }
  }

  ${media.desktop} {
    align-items: center;
    justify-content: space-between;
    margin-right: 3.958vw;
    margin-bottom: 2.778vw;
    width: 42vw;
    height: 15.347vw;
    padding-top: 2.431vw;
    padding-bottom: 2.431vw;
    padding-left: 3.403vw;
    padding-right: 3.403vw;

    :nth-of-type(even) {
      margin-right: 0px;
    }
  }

  ${media.tablet} {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.395vw;
    width: 100%;
    height: 25.988vw;
    padding-top: 4.072vw;
    padding-bottom: 4.072vw;
    padding-left: 5.868vw;
    padding-right: 5.749vw;
  }

  ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 13.067vw;
    padding-left: 8.267vw;
    padding-bottom: 13.067vw;
    padding-right: 8.267vw;
    width: 100%;
    margin-bottom: 10.667vw;
  }
`;

const AnimationWrapper = styled.div`
  position: absolute;
  z-index: 0;
  width: 100vw;
  left: 0;

  ${media.fullWidth} {
    top: 65.792vw;
    height: 53.681vw;
  }

  ${media.desktop} {
    top: 65.792vw;
    height: 53.681vw;
  }

  ${media.tablet} {
    top: 190.792vw;
    height: 53.681vw;
  }

  ${media.mobile} {
    bottom: 0
    height: 160vw;
  }
`;

const Gradient2 = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    ${colors.culturedWhite40},
    rgba(224, 224, 224, 0.8),
    ${colors.culturedWhite40}
  );
  height: 100%;
`;

const P = styled.p`
  color: ${colors.black};

  ${media.fullWidth} {
    ${text.desktopSmallBody}
    width: 22.500vw;
  }

  ${media.desktop} {
    ${text.desktopSmallBody}
    width: 22.500vw;
  }

  ${media.tablet} {
    ${text.tabletSmallBody}
    width: 38.084vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
    width: 100%;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const IconStyles = `
  height: auto;

  ${media.fullWidth} {
    width: 5.208vw;
    margin-bottom: 2.083vw;
  }

  ${media.desktop} {
    width: 5.208vw;
    margin-bottom: 2.083vw;
  }

  ${media.tablet} {
    width: 8.862vw;
    margin-bottom: 3.473vw;
  }

  ${media.mobile} {
    width: 19.733vw;
    margin-bottom: 5.333vw;
  }
`;

const Sustainability = styled(SustainabilitySVG)`
  ${IconStyles}
`;

const Innovation = styled(InnovationSVG)`
  ${IconStyles}
`;

const Information = styled(InformationSVG)`
  ${IconStyles}
`;

const Precision = styled(PrecisionSVG)`
  ${IconStyles}
`;

const Collaboration = styled(CollaborationSVG)`
  ${IconStyles}
`;

const Integrity = styled(IntegritySVG)`
  ${IconStyles}
`;

const Diversity = styled(DiversitySVG)`
  ${IconStyles}
`;

const Text = styled.span`
  color: ${colors.black};
  font-family: Helvetica Neue Medium !important;

  ${media.fullWidth} {
    ${text.desktopBodyCopy2}
    width: 11vw;
  }

  ${media.desktop} {
    ${text.desktopBodyCopy2}
    width: 11vw;
  }

  ${media.tablet} {
    ${text.tabletBodyCopy2}
    width: 16.681vw;
  }

  ${media.mobile} {
    ${text.mobileBodyCopy2}
    width: 100%;
    margin-bottom: 5.333vw;
  }
`;
